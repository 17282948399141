import PropTypes from "prop-types";
import moment from "moment";
import axios from "axios";
import SunEditor from "suneditor-react";
import { useLocation } from "react-router-dom";
import { useState, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { downloadStart } from "redux/actions/download";
import { isNil } from "lodash";
import {
  Modal,
  Grid,
  Icon,
  Button,
  Header,
  List,
  Segment,
  Loader,
  Dimmer,
  Form,
  Dropdown,
  Label,
  Table,
  Placeholder
} from "semantic-ui-react";

import { Icon as IconC } from "components/common";
import ProfileImage from "components/common/ProfileImage";
import WorkdayTooltip from "components/common/WorkdayTooltip";

import { updateProfile } from "redux/actions/editProfile";
import { getProfile, setProfilePronunciation } from "redux/actions/profile";
import { getResume } from "redux/actions/resume";
import { getProposalBios } from "redux/actions/proposalbios";
import {
  uploadProposalBioStart,
  uploadResumeStart,
  uploadResumeToProfile,
  uploadProposalBioToProfile,
  updateProposalBio,
  updateResume
} from "redux/actions/upload";

import CONFIG from "constants/config";
import LABEL from "constants/label";
import { fetchProfileCompletionData } from "components/App/handlers/handlers";
import { toastSuccess, toastDeleteSuccess, toastError, isEmptyOrNull, useQuery } from "helpers/utils";
import sendEventData from "analytics/analytics";
import { TRIGGERS, EVENTS, NOTIFICATION_EVENTS } from "analytics/constants";
import { sortProposalsByDate } from "../ProfileSummary/helper/utils";

import "react-semantic-toasts/styles/react-semantic-alert.css";
import "suneditor/dist/css/suneditor.min.css";
import "./styles.scss";

const { COMMON, EDIT_PROFILE, PROFILE_SUMMARY } = LABEL;
const { PREFFERED_CONTACT_TRIGGER } = EDIT_PROFILE;
const { apiUrl, xApiKey } = CONFIG;
const MAX_FILE_SIZE = process.env.REACT_APP_MAX_FILE_SIZE;

/**
 * Edit Profile
 *
 * @param  {object} user user information object
 * @param  {boolean} open popup open/close state
 * @param  {func} onOpen fires on opening of popup
 * @param  {func} onClose fires on closing od popup
 * @param  {object} resume resume information object
 *
 * @return Edit Profile component
 */

const EditProfile = ({ user, open, onOpen, onClose, resume, proposalBios, isProfileCompletionMode }) => {
  const {
    profileImage,
    uploadResumeStatus,
    uploadBioStatus,
    profileUpdating,
    profileLoading,
    profileResumeError,
    profileProposalBiosError,
    pronunciation,
    peopleData,
    profile,
    uploadBioError,
    uploadResumeError,
    profileUpdatingError,
    updateBioStatus,
    updateBioError,
    updateResumeStatus,
    updateResumeError,
    languages
  } = useSelector((state) => state);
  const languageOption = languages?.languageList;
  let showProposals = true;
  const { workerURL: workdayURL } = peopleData;
  const { personalInterest = "", summary = "" } = user;
  const location = useLocation();
  const query = useQuery(location?.search);
  const campaignSource = query.get(NOTIFICATION_EVENTS.CAMPAIGN_SOURCE);
  const campaignText = query.get(NOTIFICATION_EVENTS.CAMPAIGN_TEXT);

  const [isUpdating, setIsUpdating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const url = apiUrl.linkApi;

  const dispatch = useDispatch();

  // Axios cancel req source
  const cancelTokenSource = axios.CancelToken.source();

  // Cancel popup state

  const [isdirty, setIsDirty] = useState(false);
  const [isSummaryUpdated, setIsSummaryUpdated] = useState(false);
  const [openWarnPopup, setOpenWarnPopup] = useState(false);

  // loading state
  const [loading, setLoading] = useState(false);

  // placeholder state for editors
  const [interestPlaceholder, setInterestPlaceholder] = useState(null);
  const [descPlaceholder, setDescPlaceholder] = useState(null);

  //  Text editor option

  const customHr = {
    name: "customHr",
    display: "command",
    title: "Horizontal Line",
    buttonClass: "",
    innerHTML:
      "<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 15.74 2.24'><g><path d='M20.15,12.75V10.51H4.41v2.24H20.15Z' transform='translate(-4.41 -10.51)'></path></g></svg>",

    add() {
      // maintain context here
    },
    action() {
      const html = "<div contenteditable='false'></div><hr contenteditable='false'><p><br></p>";
      // eslint-disable-next-line react/no-this-in-sfc
      this.functions.core.execCommand("insertHTML", false, html);
    }
  };

  const editorOption = {
    katex: "window.katex", // Math plugin

    buttonList: [["bold", "italic", "underline", "redo", "undo", "removeFormat", "customHr", "link"], ["formatBlock"]],
    formats: ["h2", "h3", "p"],
    plugins: [customHr]
  };

  // TextEditor Focus

  const [highlightDesc, setHighlightDesc] = useState(null);
  const [highlightPI, setHighlightPI] = useState(null);

  const handleFocusDesc = () => {
    setHighlightDesc("editor-focused");
  };
  const handleFocusPI = () => {
    setHighlightPI("editor-focused");
  };

  const handleBlur = () => {
    setHighlightDesc(null);
    setHighlightPI(null);
  };

  // Text editor state

  const [interest, setInterest] = useState(personalInterest);
  const [desc, setDesc] = useState(summary);

  useEffect(() => {
    setInterest(personalInterest);
    setDesc(summary);
  }, [personalInterest, summary]);

  useEffect(() => {
    setInterestPlaceholder("Personal Interests");
    setDescPlaceholder("Description");
  }, [open]);

  const regex = /(<\/?[^>]+(>|$)|&nbsp;|\s)/gi;

  // Proposal Bio
  const [proBio, setProBio] = useState([]);
  const [iptFiles, setIptFiles] = useState([]);
  const [preBCGResume, setPreBCGResume] = useState([]);
  const [iptFileResume, setIptFileResume] = useState([]);
  const [iptFileType, setIptFileType] = useState({ type: "", extAllowed: true });
  const [openDeletePopup, setOpenDeletePopup] = useState({ bool: false, id: "", index: "", type: "", loading: false });
  const [fileSize, setFileSize] = useState(0);

  // preferred contact method state
  const [phonePreMethod, setPhonePreMethod] = useState(user.phonePreferred);
  const [mobilePreMethod, setMobilePreMethod] = useState(user.mobilePreferred);
  const [workPhonePreMethod, setWorkPhonePreMethod] = useState(user.workPhone1Preferred);

  const filesBottom = useRef(null);
  const loadingMsg = useRef(null);

  const scrollToSection = (section, timeout = 0) => {
    setTimeout(() => {
      section.scrollIntoView({ behavior: "smooth" });
    }, timeout);
  };

  useEffect(() => {
    setProBio(JSON.parse(JSON.stringify(proposalBios)));
    setPreBCGResume(resume?.title ? [{ ...resume }] : []);
  }, [proposalBios, resume]);

  const didMount = useRef(false);
  useEffect(() => {
    if (!isNil(filesBottom.current) && !openDeletePopup.bool && !openDeletePopup.loading && didMount.current)
      scrollToSection(filesBottom.current, 100);
    if (iptFiles.length > 0 || iptFileResume.length > 0) setIsDirty(true);
  }, [iptFiles.length, iptFileResume.length, proBio.length, preBCGResume.length, openDeletePopup.bool, openWarnPopup]);

  useEffect(() => {
    if (!isNil(loadingMsg.current) && loading) scrollToSection(loadingMsg.current);
  }, [loading]);

  if (
    !resume.fileName &&
    proBio.length === 0 &&
    iptFiles.length === 0 &&
    preBCGResume.length === 0 &&
    iptFileResume.length === 0
  ) {
    showProposals = false;
  }

  const handleFileIpt = ({ target }) => {
    window.$tabName = PROFILE_SUMMARY.ProfileSummary; // Global variable for Analytics
    sendEventData(TRIGGERS.PROFILE_SUMMARY.EDIT_SUMMARY, EVENTS.PROFILE_SUMMARY); // Analytics Event
    const { files } = target;

    if (!isNil(files)) {
      const extension = `.${files[0].name.split(".").pop()}`; // get file extension
      const size = files[0].size / (1024 * 1024); // convert file size to MBs
      setFileSize(size);
      if (!EDIT_PROFILE.AllowedExtPB.find((ext) => ext === extension?.toLowerCase())) {
        setIptFileType((prevState) => {
          const newState = { ...prevState };
          newState.extAllowed = false;
          newState.ext = extension;
          setTimeout(() => {
            newState.extAllowed = true;
          }, 2000);
          return newState;
        });
        return false;
      }
      if (size > MAX_FILE_SIZE) {
        return false;
      }

      didMount.current = true;

      if (iptFileType.type === EDIT_PROFILE.Resume) {
        setIptFileResume([
          {
            fileInfo: {
              title: files[0].name,
              language: 0,
              isDeleted: false,
              filePath: `data/profile/resumes/${user?.hrId}/${+new Date()}/${user?.hrId}_${+new Date()}${extension}`,
              type: "Resume",
              creationDate: +new Date()
            },
            file: files[0]
          }
        ]);
      } else {
        setIptFiles((prevState) => {
          const newState = Array.from(prevState);
          newState.push({
            fileInfo: {
              title: files[0].name,
              language: 0,
              isDeleted: false,
              filePath: `data/profile/proposalBio/${user?.hrId}/${+new Date()}/${
                user?.hrId
              }_${+new Date()}${extension}`,
              type: "Proposal",
              creationDate: +new Date()
            },
            file: files[0]
          });
          return newState;
        });
      }
    }
    return null;
  };

  function handleLangIpt(index, sel, setState, file, fileId) {
    didMount.current = true;
    setState((prevState) => {
      let newState = Array.from(prevState);

      if (file) {
        newState[index]["fileInfo"]["language"] = sel.value;
      } else {
        newState = newState.map((fileObj) => {
          const newFileObj = { ...fileObj, id: fileObj.resumeId || fileObj.proposalBioId };
          if (newFileObj.id === fileId) {
            newFileObj.language = sel.value;
            setIsDirty(true);
          }
          return newFileObj;
        });
      }
      return newState;
    });
  }

  const handleTypeIpt = (e, sel) => {
    setIptFileType((prevState) => {
      const newState = { ...prevState };
      newState.type = sel.value;
      return newState;
    });
  };

  const handleDeleteFile = async (fileId, fileIndex, fileType) => {
    didMount.current = true;
    if (isNil(fileId)) {
      setOpenDeletePopup((prevState) => {
        return { ...prevState, bool: false };
      });

      if (!openDeletePopup.id) {
        if (fileType === EDIT_PROFILE.Proposal) {
          setIptFiles((prevState) => {
            const newState = Array.from(prevState);
            newState.splice(openDeletePopup.index, 1);
            return newState;
          });
        } else {
          setIptFileResume((prevState) => {
            const newState = Array.from(prevState);
            newState.splice(openDeletePopup.index, 1);
            return newState;
          });
        }
      } else {
        try {
          setIsDeleting(true);
          setLoading(true);
          setOpenDeletePopup((prevState) => {
            return { ...prevState, loading: true };
          });

          if (openDeletePopup.type !== "Resume") {
            const fileInfo = proBio.find((file) => file.proposalBioId === openDeletePopup.id);
            dispatch(
              uploadProposalBioToProfile({
                endpoint: `${apiUrl.linkApi}profile/${user?.hrId}/proposalbio`,
                data: {
                  ...fileInfo,
                  isDeleted: true
                },
                config: {
                  headers: {
                    "x-api-key": `${xApiKey.profile}`
                  }
                },
                totalItems: 1,
                currentItem: 1
              })
            );
          } else {
            const fileInfo = preBCGResume[0];
            dispatch(
              uploadResumeToProfile({
                endpoint: `${apiUrl.linkApi}profile/${user?.hrId}/resume`,
                data: {
                  ...fileInfo,
                  isDeleted: true
                },
                config: {
                  headers: {
                    "x-api-key": `${xApiKey.profile}`
                  }
                }
              })
            );
          }
        } catch (e) {
          setIsDeleting(false);
          setOpenDeletePopup({ bool: false, id: "", index: "", type: "", loading: false });
          setLoading(false);
          toastError();
        }
      }
    } else {
      setOpenDeletePopup((prevState) => {
        return { ...prevState, bool: true, id: fileId, index: fileIndex, type: fileType };
      });
    }
  };

  const deleteFileWrapUp = () => {
    setIsDeleting(false);
    setOpenDeletePopup({ bool: false, id: "", index: "", type: "", loading: false });

    setLoading(false);
  };

  useEffect(() => {
    if (!uploadBioStatus && openDeletePopup.loading && isDeleting) {
      if (isEmptyOrNull(profileProposalBiosError)) {
        dispatch(
          getProposalBios({
            endpoint: `${apiUrl.linkApi}profile/${user?.hrId}/proposalbio`,
            config: {
              headers: {
                "x-api-key": `${xApiKey.profile}`
              }
            }
          })
        );
        fetchProfileCompletionData(user?.hrId, dispatch);
      } else {
        deleteFileWrapUp();
        toastError();
      }
    }
  }, [uploadBioStatus]);

  useEffect(() => {
    if (!uploadResumeStatus && openDeletePopup.loading && isDeleting) {
      if (isEmptyOrNull(profileProposalBiosError)) {
        dispatch(
          getResume({
            endpoint: `${apiUrl.linkApi}profile/${user?.hrId}/resume`,
            config: {
              headers: {
                "x-api-key": `${xApiKey.profile}`
              }
            }
          })
        );
        fetchProfileCompletionData(user?.hrId, dispatch);
      } else {
        deleteFileWrapUp();
        toastError();
      }
    }
  }, [uploadResumeStatus]);

  useEffect(() => {
    if (!uploadBioStatus && openDeletePopup.loading && isDeleting) {
      deleteFileWrapUp();
      if (!uploadBioError) {
        toastDeleteSuccess();
      } else {
        toastError();
      }
    }
  }, [uploadBioStatus]);

  useEffect(() => {
    if (!uploadResumeStatus && openDeletePopup.loading && isDeleting) {
      deleteFileWrapUp();
      if (!uploadResumeError) {
        toastDeleteSuccess();
      } else {
        toastError();
      }
    }
  }, [uploadResumeStatus]);

  const closeDeletePopup = () => {
    setOpenDeletePopup((prevState) => {
      return { ...prevState, bool: false };
    });
  };

  const handleDownloadFile = (fileId, fileType) => {
    try {
      dispatch(
        downloadStart({
          endpoint: `${apiUrl.downloadFile}download/v2/${fileId}/document?type=${
            fileType === "Resume" ? "Resume" : "Proposal_Bio"
          }&appName=Profiles`,
          config: {
            headers: {
              "x-api-key": `${xApiKey.profile}`
            }
          }
        })
      );
    } catch (e) {
      return false;
    }
    return null;
  };

  // generate resume and proposal bio structure
  const getProposalResume = useMemo(() => {
    const proposalBiosArr = [];

    if (showProposals) {
      const langOptions = languageOption?.map((lang) => ({
        key: lang?.languageId,
        text: lang?.name,
        value: lang?.languageId
      }));

      const trigger = <Icon className="cursor-pointer" name="ellipsis horizontal" data-testid="share" />;

      const proBioContent = (item, index, setState, file) => {
        const fileType = item.type === "Resume" ? EDIT_PROFILE.Resume : EDIT_PROFILE.Proposal;
        return (
          <Table.Row key={`${item?.resumeId || item?.proposalBioId}-${index}`} verticalAlign="top">
            <Table.Cell width={6}>
              <strong>{item.title}</strong>
            </Table.Cell>
            <Table.Cell width={3}>
              <strong>{fileType}</strong>
            </Table.Cell>
            <Table.Cell width={3}>
              <strong>{moment(item.creationDate).format("MMM YYYY")}</strong>
            </Table.Cell>
            <Table.Cell width={3} className={!item.language ? "edit-profile__mandatoryField" : ""}>
              <Dropdown
                selection
                fluid
                placeholder="Choose..."
                value={item.language}
                options={langOptions}
                onChange={(e, sel) => handleLangIpt(index, sel, setState, file, item?.resumeId || item?.proposalBioId)}
                className="edit-profile__langDropdown"
              />
            </Table.Cell>
            <Table.Cell width={1} className="edit-profile-optDropdown edit-profile__optDropdown">
              <Dropdown text={trigger} icon="" labeled compact button>
                <Dropdown.Menu>
                  <Dropdown.Item
                    key={1}
                    text={EDIT_PROFILE.DownloadPB}
                    value={EDIT_PROFILE.DownloadPB}
                    onClick={() => handleDownloadFile(item?.resumeId || item?.proposalBioId, fileType)}
                    disabled={item.id === 0}
                  />
                  <Dropdown.Item
                    key={2}
                    text={EDIT_PROFILE.DeletePB}
                    value={EDIT_PROFILE.DeletePB}
                    onClick={() => handleDeleteFile(item?.resumeId || item?.proposalBioId, index, fileType)}
                  />
                </Dropdown.Menu>
              </Dropdown>
            </Table.Cell>
          </Table.Row>
        );
      };

      const proBios = [...preBCGResume, ...proBio];
      sortProposalsByDate(proBios);

      proBios.forEach((item, index) => {
        proposalBiosArr.push(proBioContent(item, index, item.type === "Resume" ? setPreBCGResume : setProBio, false));
      });
      iptFiles.forEach((itemObj, index) => {
        proposalBiosArr.push(proBioContent(itemObj.fileInfo, index, setIptFiles, true));
      });
      iptFileResume.forEach((itemObj, index) => {
        proposalBiosArr.push(proBioContent(itemObj.fileInfo, index, setIptFileResume, true));
      });
    }

    return { proposalBiosArr };
  }, [showProposals, proBio, preBCGResume, iptFiles, iptFileResume, languageOption?.length]);

  const handleChange = (value) => {
    setIsSummaryUpdated(true);
    if (value.replace(regex, "").length === 0) {
      setInterest("");
    } else {
      setInterest(value);
    }
    if (value.replace(regex, "") === interest.replace(regex, "")) {
      setIsSummaryUpdated(false);
    }
  };

  const handleChangeDesc = (value) => {
    setIsSummaryUpdated(true);
    if (value.replace(regex, "").length === 0) {
      setDesc("");
    } else {
      setDesc(value);
    }
    if (value.replace(regex, "") === desc.replace(regex, "")) {
      setIsSummaryUpdated(false);
    }
  };

  // on Close button

  const checkDirty = () => {
    sendEventData(
      TRIGGERS.COMMON.CANCEL,
      EVENTS.PROFILE_SUMMARY,
      campaignSource && {
        campaignSource,
        campaignText
      }
    );
    if (loading) {
      cancelTokenSource.cancel();
      setLoading(false);
    }

    if (isdirty === true) {
      setOpenWarnPopup(true);
    } else {
      onClose();
    }
  };

  // close warning popup

  const closeWarningPopup = () => {
    setOpenWarnPopup(false);
  };

  // close all popup

  const closeAllPopup = () => {
    setOpenWarnPopup(false);
    setIsDirty(false);
    onClose();
    setInterest(user?.personalInterest);
    setDesc(user?.summary);
  };

  // Form Submit

  const handleSubmit = async () => {
    window.$tabName = PROFILE_SUMMARY.ProfileSummary; // Global variable for Analytics
    sendEventData(
      TRIGGERS.PROFILE_SUMMARY.SAVE,
      EVENTS.PROFILE_SUMMARY,
      campaignSource && {
        campaignSource,
        campaignText
      }
    ); // Analytics Event
    try {
      setLoading(true);

      const data = {
        ...user,
        hrId: null,
        personalInterest: btoa(encodeURI(interest)),
        summary: btoa(encodeURI(desc)),
        isSummaryUpdated,
        isBase64Encoded: true
      };

      const proBiosToUpdate = proBio?.filter((file, index) => file?.language !== proposalBios[index]?.language);

      proBiosToUpdate.forEach((file, cuurentIndex) => {
        dispatch(
          updateProposalBio({
            endpoint: `${apiUrl.linkApi}profile/${user?.hrId}/proposalbio`,
            data: {
              ...file
            },
            config: {
              headers: {
                "x-api-key": `${xApiKey.profile}`
              }
            },
            totalItems: proBiosToUpdate?.length,
            currentItem: cuurentIndex + 1
          })
        );
      });

      const resumesToUpdate = preBCGResume?.filter((file) => file?.language !== resume?.language);

      resumesToUpdate.forEach((file) => {
        dispatch(
          updateResume({
            endpoint: `${apiUrl.linkApi}profile/${user?.hrId}/resume`,
            data: {
              ...file
            },
            config: {
              headers: {
                "x-api-key": `${xApiKey.profile}`
              }
            }
          })
        );
      });

      dispatch(
        updateProfile({
          endpoint: `${url}profile/${user?.hrId}/basic`,
          data,
          config: {
            headers: {
              "x-api-key": `${xApiKey.profile}`
            }
          }
        })
      );

      if (iptFileResume.length > 0) {
        dispatch(
          uploadResumeStart({
            endpoint: `${apiUrl.uploadPreflight}?fileName=${iptFileResume[0]?.fileInfo?.filePath}&bucketName=${process.env.REACT_APP_UPLOAD_AWS_S3_BUCKET}`,
            config: {
              headers: {
                "x-api-key": `${xApiKey.upload}`
              }
            },
            data: iptFileResume[0].file,
            fileInfo: iptFileResume[0].fileInfo,
            hrId: user?.hrId
          })
        );
      }

      if (iptFiles.length > 0)
        iptFiles.forEach((formObj, cuurentIndex) => {
          dispatch(
            uploadProposalBioStart({
              endpoint: `${apiUrl.uploadPreflight}?fileName=${formObj?.fileInfo?.filePath}&bucketName=${process.env.REACT_APP_UPLOAD_AWS_S3_BUCKET}`,
              config: {
                headers: {
                  "x-api-key": `${xApiKey.upload}`
                }
              },
              data: formObj.file,
              fileInfo: formObj?.fileInfo,
              totalItems: iptFiles?.length,
              currentItem: cuurentIndex + 1,
              hrId: user?.hrId
            })
          );
        });

      setIsUpdating(true);
    } catch (e) {
      setIsUpdating(false);
      setLoading(false);
      toastError();
    }
  };

  const handleSubmitWrapUp = () => {
    setIsUpdating(false);
    setLoading(false);
    setIsDirty(false);
  };

  useEffect(() => {
    if (
      !profileUpdating &&
      isUpdating &&
      !uploadBioStatus &&
      !uploadResumeStatus &&
      !updateBioStatus &&
      !updateResumeStatus
    ) {
      if (isEmptyOrNull(profileResumeError) && isEmptyOrNull(profileProposalBiosError)) {
        dispatch(
          getProfile({
            endpoint: `${apiUrl.linkApi}profile/${user?.hrId}/basic`,
            config: {
              headers: {
                "x-api-key": `${xApiKey.profile}`
              }
            }
          })
        );
        dispatch(
          getResume({
            endpoint: `${apiUrl.linkApi}profile/${user?.hrId}/resume`,
            config: {
              headers: {
                "x-api-key": `${xApiKey.profile}`
              }
            }
          })
        );
        dispatch(
          getProposalBios({
            endpoint: `${apiUrl.linkApi}profile/${user?.hrId}/proposalbio`,
            config: {
              headers: {
                "x-api-key": `${xApiKey.profile}`
              }
            }
          })
        );
        fetchProfileCompletionData(user?.hrId, dispatch);
        toastSuccess();
      } else {
        onClose();
        handleSubmitWrapUp();
        toastError();
      }
    }
  }, [profileUpdating, uploadResumeStatus, uploadBioStatus, updateBioStatus, updateResumeStatus]);

  useEffect(() => {
    if (
      !profileLoading &&
      isUpdating &&
      !uploadBioStatus &&
      !uploadResumeStatus &&
      !updateBioStatus &&
      Object.keys(profile)?.indexOf("audioId") === -1
    ) {
      handleSubmitWrapUp();
      if (
        !uploadBioError &&
        !uploadResumeError &&
        !updateBioError &&
        !updateResumeError &&
        isEmptyOrNull(profileUpdatingError)
      ) {
        onClose();
      } else {
        toastError();
      }

      dispatch(
        setProfilePronunciation({
          id: pronunciation.audioId,
          url: pronunciation?.playbackUrl || pronunciation?.audioPath
        })
      );
    }
  }, [profileLoading, uploadBioStatus, uploadResumeStatus, updateBioStatus, uploadResumeStatus]);

  // star icon for preferred contact method
  const imgStarOn = <IconC name="star-on" size={16} className="cursor-pointer" />;
  const imgStarOff = <IconC name="star-off" size={16} className="cursor-pointer" />;
  const imgBlank = <IconC name="blank_white" size={16} className="cursor-pointer" />;

  // converting workphone1 values to an array.
  const workphone = user.workPhone1?.split(",");
  const anyPreValue = user.phone?.length > 0 || user.mobile?.length > 0 || workphone?.length > 0;

  // handling preferred contact method click event
  const handlePreferred = (prefer) => {
    switch (prefer) {
      case PREFFERED_CONTACT_TRIGGER.OFFICE:
        setPhonePreMethod((prevState) => {
          if (!prevState) {
            setMobilePreMethod(false);
            setWorkPhonePreMethod(false);
          }
          return !prevState;
        });
        break;
      case PREFFERED_CONTACT_TRIGGER.MOBILE:
        setMobilePreMethod((prevState) => {
          if (!prevState) {
            setPhonePreMethod(false);
            setWorkPhonePreMethod(false);
          }
          return !prevState;
        });
        break;
      case PREFFERED_CONTACT_TRIGGER.WORK:
        setWorkPhonePreMethod((prevState) => {
          if (!prevState) {
            setMobilePreMethod(false);
            setPhonePreMethod(false);
          }
          return !prevState;
        });
        break;
      default:
    }
    setIsDirty(true);
  };
  const handleUploadClick = (e) => {
    window.$tabName = PROFILE_SUMMARY.ProfileSummary; // Global variable for Analytics
    sendEventData(TRIGGERS.PROFILE_SUMMARY.UPLOAD, EVENTS.PROFILE_SUMMARY); // Analytics Event
    e.target.value = null;
  };
  return (
    <>
      <form onSubmit={handleSubmit}>
        <Modal
          onClose={onClose}
          onOpen={onOpen}
          open={open}
          centered={false}
          closeOnDimmerClick={false}
          className={`${openWarnPopup || openDeletePopup.bool ? "d-none" : ""} custom-modal custom-modal-editProfile`}
        >
          <Modal.Header className="heading">{EDIT_PROFILE.EditProfile}</Modal.Header>
          <Modal.Content className="desc py-0 pt-0 position-relative">
            <>
              {loading ? (
                <Segment className="edit-profile__loadingSegment">
                  <Dimmer active inverted>
                    <Loader size="large">{openDeletePopup.loading ? "Deleting" : "Saving"}</Loader>
                  </Dimmer>
                </Segment>
              ) : (
                <></>
              )}
              {/* <section className="edit-profile__top-bar">
                <CompletionBar
                  percentComplete={percentComplete}
                  profileModules={profileModules}
                  workdayWorkerUrl={workDayURL}
                />
              </section> */}
              <>
                {!isProfileCompletionMode ? (
                  <>
                    <Grid className="edit-profile__sectionBlock">
                      <Grid.Row className="pt-0">
                        <Grid.Column computer={3} tablet={3} mobile={6}>
                          {!profileImage?.img ? (
                            <Placeholder className="banner-placeholder">
                              <Placeholder.Image />
                            </Placeholder>
                          ) : (
                            <ProfileImage imgSrc={(user && profileImage?.img) || ""} size="medium" />
                          )}
                        </Grid.Column>
                        <Grid.Column computer={13} tablet={13} mobile={10}>
                          <Grid className="edit-profile__profileList">
                            <Grid.Row className="pb-0">
                              <Grid.Column computer={12} tablet={16} mobile={16}>
                                <div className="fs-12 mb-h">
                                  <Icon name="info circle" className="edit-profile__profileList__icon" />{" "}
                                  {EDIT_PROFILE.UpdateFromWorkdayStart}{" "}
                                  <a href={workdayURL} target="_blank" rel="noopener noreferrer">
                                    Workday
                                  </a>{" "}
                                  {EDIT_PROFILE.UpdateFromWorkdayEnd}
                                </div>
                              </Grid.Column>
                            </Grid.Row>
                            <Grid.Row className="pb-0">
                              <Grid.Column computer={5} tablet={16} mobile={16}>
                                <div className="edit-profile__profileList__block">
                                  <p>{EDIT_PROFILE.Name}</p>
                                  <p>
                                    <strong>
                                      {peopleData?.preferredFirstName} {peopleData?.preferredLastName}
                                    </strong>
                                  </p>
                                </div>
                                <div className="edit-profile__profileList__block">
                                  <p>{EDIT_PROFILE.Position}</p>
                                  <p>
                                    <strong>{peopleData?.jobPosition}</strong>
                                  </p>
                                </div>
                                <div className="edit-profile__profileList__block">
                                  <p>{EDIT_PROFILE.Office}</p>
                                  <p>
                                    <strong>{peopleData?.hostOfficeLocation?.name}</strong>
                                  </p>
                                </div>
                              </Grid.Column>
                              <Grid.Column computer={5} tablet={16} mobile={16}>
                                <div className="edit-profile__profileList__block">
                                  <p>{EDIT_PROFILE.Email}</p>
                                  <p>
                                    <strong>{peopleData?.workEmail}</strong>
                                  </p>
                                </div>
                                <div className="edit-profile__profileList__block">
                                  <p>{EDIT_PROFILE.Assistant}</p>
                                  <p>
                                    <strong>
                                      {peopleData?.assistants?.length > 0 &&
                                        `${peopleData?.assistants[0]?.firstName} ${peopleData?.assistants[0]?.lastName}`}
                                    </strong>
                                  </p>
                                </div>
                                <div className="edit-profile__profileList__block">
                                  <p>{EDIT_PROFILE.AssistantEmail}</p>
                                  <p>
                                    <strong>
                                      {peopleData?.assistants?.length > 0 && peopleData?.assistants[0]?.email}
                                    </strong>
                                  </p>
                                </div>
                              </Grid.Column>
                              <Grid.Column computer={5} tablet={16} mobile={16}>
                                <div className="edit-profile__profileList__block">
                                  <p>{EDIT_PROFILE.PhoneNumber}</p>
                                  <p>
                                    <strong>
                                      <List>
                                        <List.Item>
                                          <span className="d-block mr-1">{EDIT_PROFILE.Office}</span>
                                          {peopleData?.phone !== "NULL" && peopleData?.phone}
                                        </List.Item>
                                        <List.Item>
                                          <span className="d-block mr-1">{EDIT_PROFILE.Mobile}</span>
                                          {peopleData?.primaryWorkPhone !== "NULL" && peopleData?.primaryWorkPhone}
                                        </List.Item>
                                        <List.Item>
                                          <span className="d-block mb-1">
                                            {workphone && workphone.length ? (
                                              <i
                                                aria-hidden="true"
                                                data-tooltip={LABEL.BANNER.TOOLTIPS.PreferredContact}
                                                data-inverted="true"
                                                onClick={() => handlePreferred(PREFFERED_CONTACT_TRIGGER.WORK)}
                                              >
                                                {workPhonePreMethod ? imgStarOn : imgStarOff}
                                              </i>
                                            ) : (
                                              anyPreValue && <i aria-hidden="true">{imgBlank}</i>
                                            )}{" "}
                                            {EDIT_PROFILE.Work}
                                          </span>
                                          {workphone &&
                                            workphone?.map((element) => (
                                              <b key={element}>
                                                &nbsp;&nbsp;{element}
                                                <br />
                                                <br />
                                              </b>
                                            ))}
                                        </List.Item>
                                      </List>
                                    </strong>
                                  </p>
                                </div>
                              </Grid.Column>
                            </Grid.Row>
                          </Grid>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                    <hr className="edit-profile__divider" ref={loadingMsg} />
                  </>
                ) : null}
              </>
              {/** Profile Summary Block */}
              <Grid className="edit-profile__sectionBlock" id="profile-summary">
                <Grid.Row>
                  <Grid.Column className="pr-3" computer={4} tablet={16} mobile={16}>
                    <Header as="h5" className="edit-profile__tipsHeading">
                      {EDIT_PROFILE.TIPS}
                    </Header>
                    <p className="fs-12">{EDIT_PROFILE.TipsSM}</p>
                    <List as="ol" className="edit-profile__tipsList">
                      <List.Item as="li" value="-">
                        {EDIT_PROFILE.TipsSMone}
                      </List.Item>
                      <List.Item as="li" value="-">
                        {EDIT_PROFILE.TipsSMtwo}
                      </List.Item>
                      <List.Item as="li" value="-">
                        {EDIT_PROFILE.TipsSMthree}
                      </List.Item>
                    </List>
                  </Grid.Column>
                  <Grid.Column computer={12} tablet={16} mobile={16}>
                    <Header as="h4" className="edit-profile__sectionBlock__subHeading">
                      {EDIT_PROFILE.ProfileSummary}
                    </Header>
                    <div data-testid="descEditor" className={highlightDesc}>
                      <SunEditor
                        height="200"
                        defaultValue={desc}
                        onChange={handleChangeDesc}
                        setOptions={editorOption}
                        onFocus={handleFocusDesc}
                        onBlur={handleBlur}
                        placeholder={descPlaceholder}
                      />
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              {/** Profile Personal Interest Block */}
              {!isProfileCompletionMode ? (
                <>
                  <hr className="edit-profile__divider" />
                  <Grid className="edit-profile__sectionBlock">
                    <Grid.Row>
                      <Grid.Column className="pr-3" computer={4} tablet={16} mobile={16}>
                        <Header as="h5" className="edit-profile__tipsHeading">
                          {EDIT_PROFILE.TIPS}
                        </Header>
                        <p className="fs-12">{EDIT_PROFILE.TipsPI}</p>
                      </Grid.Column>
                      <Grid.Column computer={12} tablet={16} mobile={16}>
                        <Header as="h4" className="edit-profile__sectionBlock__subHeading">
                          {EDIT_PROFILE.PersonalInterests}
                        </Header>
                        <div data-testid="interestEditor" className={highlightPI}>
                          <SunEditor
                            height="100"
                            defaultValue={interest}
                            onChange={handleChange}
                            setOptions={editorOption}
                            onFocus={handleFocusPI}
                            onBlur={handleBlur}
                            placeholder={interestPlaceholder}
                          />
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </>
              ) : null}

              {!isProfileCompletionMode ? (
                <>
                  <hr className="edit-profile__divider" />
                  <Grid className="edit-profile__sectionBlock">
                    <Grid.Row>
                      <Grid.Column className="pr-2" computer={4} tablet={16} mobile={16}>
                        <Header as="h5" className="edit-profile__tipsHeading">
                          {EDIT_PROFILE.TIPS}
                        </Header>
                        <p className="fs-12">{EDIT_PROFILE.TipsLA}</p>
                      </Grid.Column>
                      <Grid.Column computer={12} tablet={16} mobile={16}>
                        <Header as="h4" className="edit-profile__sectionBlock__subHeading">
                          {EDIT_PROFILE.Languages}
                        </Header>
                        <Grid>
                          <Grid.Row>
                            <Grid.Column computer={16} tablet={16} mobile={16}>
                              <Header as="h3" className="edit-profile__lang">
                                {peopleData?.languages?.map((item) => (
                                  <span className="mr-h" key={item?.language}>
                                    {item?.language} <span>|</span>
                                  </span>
                                ))}
                              </Header>
                              <p className="d-flex">
                                <Icon name="info circle" className="edit-profile__profileList__icon" />
                                <WorkdayTooltip keyword="Languages" className="ml-h" workdayWorkerUrl={workdayURL} />
                              </p>
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </>
              ) : null}

              <hr className="edit-profile__divider" />
              <Grid className="edit-profile__sectionBlock" id="proposal-bio">
                <Grid.Row>
                  <Grid.Column className="pr-2" computer={4} tablet={16} mobile={16}>
                    <Header as="h5" className="edit-profile__tipsHeading">
                      {EDIT_PROFILE.TIPS}
                    </Header>
                    <p className="fs-12">{EDIT_PROFILE.TipsPBOne}</p>
                    <p className="fs-12">{EDIT_PROFILE.TipsPBTwo}</p>
                    <p className="fs-12">{EDIT_PROFILE.TipsPBThree}</p>
                    <p className="fs-12">{EDIT_PROFILE.TipsPBFour}</p>
                  </Grid.Column>
                  <Grid.Column computer={12} tablet={16} mobile={16}>
                    <Header as="h4" className="edit-profile__sectionBlock__subHeading">
                      {EDIT_PROFILE.ProposalBio}
                    </Header>
                    <Grid>
                      <Grid.Row className="pb-0">
                        <Grid.Column computer={16} tablet={16} mobile={16}>
                          {showProposals && (
                            <Table>
                              <Table.Header>
                                <Table.Row className="edit-profile__proBioTable__head">
                                  <Table.Cell width={6}>{EDIT_PROFILE.NamePB}</Table.Cell>
                                  <Table.Cell width={3}>{EDIT_PROFILE.TypePB}</Table.Cell>
                                  <Table.Cell width={3}>{EDIT_PROFILE.UploadedDatePB}</Table.Cell>
                                  <Table.Cell width={3}>
                                    {EDIT_PROFILE.LanguagePB} <span>*</span>
                                  </Table.Cell>
                                  <Table.Cell width={1}>&nbsp;</Table.Cell>
                                </Table.Row>
                              </Table.Header>
                              <Table.Body className="edit-profile__proBioTable">
                                {getProposalResume.proposalBiosArr}
                                <Table.Row>
                                  <Table.Cell>
                                    <div ref={filesBottom} />
                                  </Table.Cell>
                                </Table.Row>
                              </Table.Body>
                            </Table>
                          )}
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row className="pb-0">
                        <Grid.Column computer={16} tablet={16} mobile={16}>
                          <Form>
                            <Form.Group inline className="edit-profile-radioGroup">
                              <Form.Radio
                                label={EDIT_PROFILE.ProposalIpt}
                                name="fileType"
                                value={EDIT_PROFILE.Proposal}
                                checked={iptFileType.type === EDIT_PROFILE.Proposal}
                                onChange={handleTypeIpt}
                              />
                              <Form.Radio
                                label={EDIT_PROFILE.ResumeIpt}
                                name="fileType"
                                value={EDIT_PROFILE.Resume}
                                checked={iptFileType.type === EDIT_PROFILE.Resume}
                                onChange={handleTypeIpt}
                                disabled={resume?.title}
                              />
                            </Form.Group>
                          </Form>
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row className="pb-0">
                        <Grid.Column computer={12} tablet={16} mobile={16}>
                          <div className="edit-profile__validationMsg">
                            <Label
                              as="label"
                              htmlFor="file"
                              size="small"
                              className={iptFileType.type ? "edit-profile__labelActive" : "edit-profile__labelDisabled"}
                            >
                              UPLOAD <Icon name="plus circle" />
                            </Label>
                            <input
                              hidden
                              id="file"
                              data-testid="file"
                              type="file"
                              onChange={handleFileIpt}
                              onClick={handleUploadClick}
                              disabled={!iptFileType.type}
                            />
                            {!iptFileType.extAllowed && (
                              <>
                                {" "}
                                <IconC name="warning" size={25} /> {EDIT_PROFILE.RestrictedExtPBMsg}
                              </>
                            )}
                            {fileSize > MAX_FILE_SIZE && (
                              <>
                                {" "}
                                <IconC name="warning" size={25} /> {EDIT_PROFILE.FileSizeWarning}
                              </>
                            )}
                          </div>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </>
          </Modal.Content>
          <Modal.Actions className="footer">
            <div className="edit-profile__validationMsg">
              {[...iptFiles, ...iptFileResume].some((obj) => obj["fileInfo"]["language"].length === 0) && (
                <>
                  <IconC name="warning" size={32} /> {EDIT_PROFILE.FieldValidationMsg}
                </>
              )}
            </div>
            <Button basic secondary onClick={checkDirty}>
              {COMMON.Cancel}
            </Button>
            <Button
              primary
              type="submit"
              onClick={handleSubmit}
              disabled={
                loading ||
                [...iptFiles, ...iptFileResume].some((obj) => obj["fileInfo"]["language"] === 0) ||
                !iptFileType?.extAllowed ||
                (!isdirty && !isSummaryUpdated)
              }
            >
              {COMMON.Save}
            </Button>
          </Modal.Actions>
        </Modal>
      </form>

      <Modal open={openWarnPopup} centered closeOnDimmerClick={false} className="custom-modal-small">
        <Modal.Content className="text-right p-1">
          <div className="warningpopupContent">
            <IconC name="warning" size={32} className="mr-1" /> {COMMON.CancelWarn}
          </div>
          <Button data-testid="cancel" basic className="button-sm mr-1" secondary onClick={closeWarningPopup}>
            {COMMON.No}
          </Button>
          <Button primary className="button-sm" type="submit" onClick={closeAllPopup}>
            {COMMON.Yes}
          </Button>
        </Modal.Content>
      </Modal>

      <Modal open={openDeletePopup.bool} centered closeOnDimmerClick={false} className="custom-modal-small">
        <Modal.Content className="text-right p-1">
          <div className="warningpopupContent" data-testid="delete-warn-popup">
            <IconC name="warning" size={32} className="mr-1" /> {EDIT_PROFILE.DeletePBMsg}
          </div>
          <Button data-testid="cancel" basic className="button-sm mr-1" secondary onClick={closeDeletePopup}>
            {COMMON.No}
          </Button>
          <Button primary className="button-sm" type="submit" onClick={() => handleDeleteFile(null)}>
            {COMMON.Yes}
          </Button>
        </Modal.Content>
      </Modal>
    </>
  );
};

EditProfile.defaultProps = {
  user: {},
  open: false,
  onOpen: () => {},
  onClose: () => {},
  resume: [],
  proposalBios: [],
  isProfileCompletionMode: false
};

EditProfile.propTypes = {
  user: PropTypes.shape(),
  open: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  resume: PropTypes.arrayOf(
    PropTypes.shape({ fileName: PropTypes.string, language: PropTypes.number, title: PropTypes.string })
  ),
  proposalBios: PropTypes.arrayOf(
    PropTypes.shape({ fileName: PropTypes.string, language: PropTypes.number, title: PropTypes.string })
  ),
  isProfileCompletionMode: PropTypes.bool
};

export default EditProfile;
